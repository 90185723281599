var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full w-full flex flex-col justify-center items-center relative"},[_c('div',{staticClass:"h-full w-full z-10",attrs:{"id":"map-wrap"}},[_c('GmapMap',{ref:"gMap",attrs:{"center":_vm.center,"zoom":_vm.zoom,"options":{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            styles:_vm.mapStyles
            },"id":"gMap"}},_vm._l((_vm.dots),function(el,index){return _c('gmap-custom-marker',{key:index,attrs:{"marker":el.marker},on:{"click":function($event){return _vm.tomaps(el.marker)}}},[_c('div',{staticClass:"h-5 w-5 rounded-full bg-white border-4 border-red cursor-pointer",on:{"click":function($event){return _vm.openinfo(el)}}})])}),1)],1),(_vm.tultip)?_c('div',{staticClass:"h-auto w-4/5 bg-white rounded-lg border-2 border-red m-auto z-20 absolute flex flex-col justify-center items-center py-2"},[_c('span',{staticClass:"font-semibold text-sm"},[_vm._v(_vm._s(_vm.selected.name))]),_c('a',{attrs:{"href":'https://www.google.com/maps/search/?api=1&query=' + _vm.selected.marker.lat + ',' + _vm.selected.marker.lng + ''}},[_c('span',{staticClass:"font-semibold text-2xs text-blue2"},[_vm._v(_vm._s(_vm.$t('howToGetThere')))])]),_c('div',{staticClass:"h-5 w-5 rounded-full bg-red border border-white flex flex-row justify-center items-center top-0 right-0 -mt-2 -mr-2 absolute",on:{"click":function($event){return _vm.openinfo(null)}}},[_c('i',{staticClass:"mdi mdi-close text-xs text-white"})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }