<template>
    <div :class="{'w-full h-full px-3 py-4': mobile, 'h-full p-2 overflow-hidden': !mobile}">

        <div v-if="mobile" class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'mt-24': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarcliente')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <div class="h-full w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

            <!-- <div class="h-16 flex flex-row">

                <div class="h-full w-10/12 flex flex-row">

                    <div v-if="rol == 'Representante' && employeeName" class="h-full w-3/12 flex flex-row justify-center items-center">
                        <div class="h-12 w-12 rounded-full overflow-hidden">
                            <vue-initials-img :name="employeeName || 'Sin Nombre' " class="h-12 w-12 rounded-full"/>  
                        </div>
                    </div>

                    <div v-else class="h-full w-3/12 flex flex-row justify-center items-center">
                        <div class="h-12 w-12 rounded-full overflow-hidden">
                            <vue-initials-img :name="employee.Name || 'Sin Nombre' " class="h-12 w-12 rounded-full"/>  
                        </div>
                    </div>

                    <div class="h-full w-9/12 flex flex-col justify-center items-start truncate">
                        <span v-if="rol == 'Representante' && employeeName" class="text-lg text-dfont font-semibold truncate">{{employeeName || 'Sin Nombre'}}</span>
                        <span v-else class="text-lg font-semibold truncate" :class="'text-dfont-'+mode">{{employee.Name || 'Sin Nombre'}}</span>
                        <span v-if="zona" class="text-xs text-gray">{{zona.Name}}</span>
                    </div>

                </div>

                <div class="h-full w-2/12 flex flex-row justify-end items-start">
                    <i class="mdi mdi-dots-vertical text-gray text-xl cursor-pointer" @click="tofilters()"></i>
                </div>

            </div> -->

            <All :model="model" :immediate="true" :query="query" :config="config" v-slot="{data,loading}">

                <div v-if="!loading" class="mt-2" :class="{'h-full w-full grid grid-cols-3 gap-3 overflow-hidden': !mobile, 'h-auto': mobile}">

                    <div v-if="mobile" class="h-auto flex flex-row justify-between items-center px-2">
                        <span class="text-md font-semibold" :class="'text-dfont-'+mode">{{$t('visits')}}</span>
                    </div>

                    <Intersect @enter="onEnter" @leave="onLeave" >
                    
                        <div class="px-2 mt-2 mb-8" :class="{'col-span-2 overflow-hidden h-full w-full': !mobile, 'h-72': mobile}">
                            <div class="h-full rounded-lg flex flex-row justify-center items-center overflow-hidden relative">
                                <maptool :data="filteredComercials(data.visits)"></maptool>
                                <span v-if="data.visits_latitude_longitude.length == 0" class="text-lg text-center bg-modal_transparent font-bold text-white absolute px-2 z-40">{{ $t('nolocationtext') }}</span>
                            </div>
                        </div>

                    </Intersect>

                    <div v-if="filteredComercials(data.visits) && filteredComercials(data.visits).length > 0" class="h-auto" :class="{'overflow-auto': !mobile}">
                        <client v-for="(el,index) in filteredComercials(data.visits)" :key="index" :data="el" :reducetype="true" :class="'bg-box-'+mode" father="representant"></client>
                    </div>

                    <div v-else class="h-32 flex mt-6 flex-col justify-center items-center">
                        <span :class="'text-font_gray-'+mode">{{ $t('noData') }}</span>
                    </div>
                    
                </div>

            </All>

            
            <div class="h-6"></div>
        </div>

    </div>
</template>

<script>
    import { state, actions } from '@/store';
    import maptool from '../../components/MapTool.vue';
    import { All } from '@/api/components';
    import client from '@/components/client.vue';
    import Intersect from 'vue-intersect';

    export default {
        name: 'visitas',
        components:{
            maptool,
            All,
            client,
            Intersect
        },
        data(){
            return{
                isVisible: false,
                searchInput: ''
            }
        },
        methods:{
            onLeave(){
                this.isVisible = true
            },
            onEnter(){
                this.isVisible = false
            },
            goClient(client){
                actions.setClientSelected(client)
                this.$router.push({name: 'client'})
            },
            tofilters(){
                this.$router.push({name: 'filterperiod'})
            },
            filteredComercials(data){
                return Object.values(data).filter(post => {
                    return post['Name'].toLowerCase().includes(this.searchInput.toLowerCase())
                })
            }
        },
        computed: {
            user() {
                return state.user;
            },
            comercial(){
                return state.comercialSelected
            },
            periodo(){
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;
                    case 'quarter':
                        return this.$t("quarterly")
                        break;
                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            config(){

                if(this.user.rol.Name != 'Representante' && this.user.rol.Name != 'Comercial'){

                    return{
                        data:{
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            period: state.period,
                            Zone: state.idZone,
                            Employee: state.idEmployeeSelected
                        }
                    }

                } else {

                    if(state.user.rol.Name == 'Comercial'){

                        return{
                            data:{
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                period: state.period,
                                Zone: state.idZone,
                                Employee: state.idEmployeeSelected
                            }
                        }

                    } else if(state.user.rol.Name == 'Representante'){

                        // return{
                        //     data:{
                        //         Company: state.idCompany,
                        //         period: state.period,
                        //         Employee: state.idEmployeeSelected
                        //     }
                        // }

                        if(state.idCompany){

                            return{
                                data:{
                                    Company: state.idCompany,
                                    Employee: state.idEmployeeSelected,
                                    Dimension: state.dimensionGroup,
                                }
                            }

                        } else {

                            return{
                                data:{
                                    Employee: state.idEmployeeSelected,
                                    Dimension: state.dimensionGroup,
                                }
                            }

                        }

                    }

                }

            },
            model(){

                if(this.user.rol.Name != 'Representante'){

                    if(this.comercial && this.comercial.Id){
                        
                        return 'RepresentantsviewsUser'

                    } else {

                        return 'RepresentantsviewsZone'

                    }

                } else {

                    return 'RepresentantsviewsZone'

                }

            },
            employee(){
                return state.employeSelected
            },
            zone(){
                return state.zoneSelected
            },
            query(){
                return{
                    period: state.period,
                    IndustrialGroup: state.industrialGroup
                }
            },
            zona(){
                return state.zone
            },
            rol(){
                return state.user.rol.Name
            },
            employeeName(){
                return state.employeeName
            },
            mode(){
                return state.mode
            },
            mobile(){
                return state.isMobile
            }
        },
        mounted(){
            
        }
    }
</script>