<template>
    <div class="h-full w-full flex flex-col justify-center items-center relative">
        <div id="map-wrap" class="h-full w-full z-10">
            <GmapMap
                :center="center"
                :zoom="zoom"
                :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                styles:mapStyles
                }"
                id="gMap"
                ref="gMap">
                <gmap-custom-marker v-for="(el, index) in dots" :key="index" :marker="el.marker" @click="tomaps(el.marker)">
                    <div class="h-5 w-5 rounded-full bg-white border-4 border-red cursor-pointer" @click="openinfo(el)"></div>
                </gmap-custom-marker>
            </GmapMap>
        </div>
        <div v-if="tultip" class="h-auto w-4/5 bg-white rounded-lg border-2 border-red m-auto z-20 absolute flex flex-col
            justify-center items-center py-2">
            <span class="font-semibold text-sm">{{ selected.name }}</span>
            <a class="" :href="'https://www.google.com/maps/search/?api=1&query=' + selected.marker.lat + ',' + selected.marker.lng + ''">
                <span class="font-semibold text-2xs text-blue2">{{ $t('howToGetThere') }}</span>
            </a>
            <!-- <a class="absolute left-0 ml-4" :href="'https://www.google.com/maps/search/?api=1&query=' + selected.marker.lat + ',' + selected.marker.lng + ''">
                <i class="mdi mdi-google-maps text-darkgreen"></i>
            </a> -->
            <div class="h-5 w-5 rounded-full bg-red border border-white flex flex-row justify-center items-center top-0 right-0 -mt-2 -mr-2 absolute" @click="openinfo(null)">
                <i class="mdi mdi-close text-xs text-white"></i>
            </div>
        </div>
    </div>
</template>

<script>

import { gmapApi } from 'vue2-google-maps';
import { mapColors } from '../utils/map-colors.js';
import { mapColorsDark } from '../utils/map-colors-dark.js';
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import { state, actions } from '@/store';

export default {
    props: ['data'],
    components:{
        'gmap-custom-marker': GmapCustomMarker,
        mapColors,
        mapColorsDark
    },
    data() {
        return {
            mapStyles: null,
            center: { lat: 39.46975, lng: -0.37739 },
            zoom: 5,
            selected: null,
            openmodal: false,
            tultip: false
        }
    },
    methods: {
        openinfo(name) {
            this.selected = name
            this.tultip = !this.tultip
        },
        fitBounds() {
            this.$refs.gMap.$mapPromise.then((map) => {
                if (this.dots.length) {
                    const bounds = new google.maps.LatLngBounds();
                    for (let key in this.dots) {
                        bounds.extend({
                            lat:parseFloat(this.dots[key].marker['lat']),
                            lng:parseFloat(this.dots[key].marker['lng'])
                        })
                    }
                    map.fitBounds(bounds);
                    if (map.getZoom() > this.zoom) { map.setZoom( this.zoom) }
                }
            });
        }
    },
    watch: {
        dots() {
            this.fitBounds();
        }
    },
    mounted() {

        if(this.mode == 'darkmode'){
            this.mapStyles = mapColorsDark
        } else {
            this.mapStyles = mapColors
        }

        this.fitBounds();
    },
    computed: {
        google: gmapApi,
        dots() {
            var points = []
            for (let index = 0; index < this.data.length; index++) {
                if (this.data[index].Latitude) {
                    points.push({
                        name: this.data[index].Name,
                        marker: {lat: this.data[index].Latitude, lng: this.data[index].Longitude}
                    })
                }
            }
            return points
        },
        mode(){
            return state.mode
        }
    }
}
</script>

<style lang="css">

    #gMap-wrap{
        height: 100%;
        width: 100%;
        margin: auto;
        pointer-events: none;
    }

    #gMap{
        height: 100%;
        width: 100%;
    }

    .vue-map{
        height: 100%;
        width: 100%;
    }

    img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5_hdpi.png"]
        {display:none !important}

    img[src="https://maps.gstatic.com/mapfiles/api-3/images/google5_hdpi.png"]
        {display:none !important}

    img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"]
        {display:none !important}
        
    img[src="http://maps.gstatic.com/mapfiles/api-3/images/google_white5_hdpi.png"]
        {display:none !important}

    img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4_hdpi.png"]
        {display:none !important}
        
    img[src="https://maps.gstatic.com/mapfiles/api-3/images/google4.png"]
        {display:none !important}        

    .gmnoprint {
            display: none;
        }
    .gm-style-cc{
            display: none;
        }


</style>
